import React, { Fragment, useState, useContext } from 'react';
import UserContext from "../../context/UserContext";
import Message from './Message';
import Progress from './Progress';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const FileUpload = () => {
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('Choose File');
    const [uploadedFile, setUploadedFile] = useState({});
    const [urlPath, setUrlPath] = useState('');
    const [message, setMessage] = useState('');
    const [uploadMessage, setUploadmessage] = useState('');
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isInternal, setIsInternal] = React.useState(false);
    const [isHtml, setIsHtml] = React.useState(false);

    const { userData } = useContext(UserContext);

    const onChangeFile = e => {
        if (e.target.files.length > 0) {
            setFile(e.target.files[0]);
            setFilename(e.target.files[0].name);
        }
    };

    const onSubmit = async e => {
        e.preventDefault();
        setUploadedFile({});
        setMessage(null);
        setUploadmessage('');
        const formData = new FormData();
        formData.append('file', file);
        const url = urlPath !== '' ?
            urlPath : '';
        formData.append('urlPath', url);
        formData.append('isInternal', isInternal);



        const findReport = await axios.get(`${process.env.REACT_APP_BACK_DOMAIN}/search/${url}`);

        if (findReport.data.length === 0) {
            formData.append('creator', userData.user.displayName);
        } else {
            formData.append('updater', userData.user.displayName);
        }

        try {
            if (findReport.data.length === 0
                ? window.confirm(`¿Esta seguro que desea subir este reportaje?`)
                : findReport.data[0].status
                    ? window.confirm(`Este reportaje ya existe, ¿Está seguro que desea actualizarlo?`)
                    : window.confirm(`Este reportaje fue eliminado, ¿Desea activarlo nuevamente?`)
            ) {
                setUploadmessage('El archivo se está subiendo, por favor no cierre ni actualice la página ...');
                const res = await axios.post(`${process.env.REACT_APP_BACK_DOMAIN}/${isHtml ? 'ai2Html' : 'upload'}`, formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: progressEvent => {
                            setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));

                            // Clear percentage
                            setTimeout(() => setUploadPercentage(0), 120000);
                        }
                    });

                const { fileName, filePath } = res.data;

                setUploadedFile({ fileName, filePath });
                setMessage('Reportaje Subido');
                setUploadmessage(null);
            }

        } catch (err) {
            if (err.response) {
                if (err.response.status === 500) {
                    setMessage('There was a problem with the server');
                } else {
                    setMessage(err.response.data.msg);
                }
            }
        }
    }

    const downloadTxtFile = (text) => {
        const element = document.createElement("a");
        const file = new Blob([text], {
            type: "text/plain;charset=utf-8",
        });
        element.href = URL.createObjectURL(file);
        element.download = "index-arc.txt";
        document.body.appendChild(element);
        element.click();
    }

    return (
        <Fragment>
            {message ? <Message msg={message} /> : null}
            <form onSubmit={onSubmit}>
                <div className="input-group mb-3">
                    <input type="file" className="form-control" id="inputGroupFile02" onChange={onChangeFile} />
                </div>
                <div className="mb-3">
                    <label className="form-label" id="addon-wrapping">Id</label>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Url"
                        aria-label="Username"
                        aria-describedby="addon-wrapping"
                        onChange={(e) => { setUrlPath(e.target.value) }}
                    />
                    <div id="idHelp" className="form-text">Identificador sin espacios ni caracteres especiales.</div>
                </div>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={isInternal}
                        onChange={() => { setIsInternal(!isInternal); }}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">¿Es un reportaje interno?</label>
                </div>
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={isHtml}
                        onChange={() => { setIsHtml(!isHtml); }}
                    />
                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">¿Se realizo con la herramienta ai2Html?</label>
                </div>

                <Progress percentage={uploadPercentage} />

                <button type="submit" className="btn btn-primary mt-4">Subir</button>
            </form>
            {
                uploadMessage && uploadMessage !== '' ?
                    <div className="alert alert-primary mt-4" role="alert">
                        {uploadMessage}
                    </div>
                    : ''
            }
            {
                uploadedFile ?
                    <div className="row mt-5">
                        {
                            isInternal ?
                                <>
                                    <div className="col-md-12 m-auto mb-4 ">
                                        <a href={uploadedFile.filePath} target="_blank" rel="noreferrer">
                                            <h6 className="text-center" id="result-text">{uploadedFile.filePath}</h6>
                                        </a>
                                    </div>
                                    <CopyToClipboard
                                        type="button"
                                        className="btn btn-secondary col-md-2 mb-4"
                                        text={uploadedFile.fileName}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            style={{
                                                visibility: uploadedFile.fileName ? 'visible' : 'hidden',
                                                marginRight: '10px'
                                            }}
                                        >
                                            Copiar Resultado
                                        </button>
                                    </CopyToClipboard>
                                    <button
                                        type="button"
                                        className="btn btn-secondary col-md-2 mb-4 mr-4"
                                        style={{ visibility: uploadedFile.fileName ? 'visible' : 'hidden' }}
                                        onClick={() => { downloadTxtFile(uploadedFile.fileName); }}
                                    >
                                        Descargar
                                    </button>
                                    <div className="fileContent col-md-12 m-auto">
                                        <h6 className="text-center" id="result-text">{uploadedFile.fileName}</h6>
                                    </div>
                                </>
                                :
                                <div className="col-md-12 m-auto ">
                                    <a href={uploadedFile.fileName} target="_blank" rel="noreferrer">
                                        <h6 className="text-center" id="result-text">{uploadedFile.fileName}</h6>
                                    </a>
                                </div>
                        }

                    </div>
                    : null
            }
        </Fragment>
    )
}
