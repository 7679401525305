import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDownloadExcel } from "react-export-table-to-excel";
import UserContext from "../../context/UserContext";
import ErrorNotice from "../misc/ErrorNotice";

export default function Search() {
  const [reports, setReports] = useState([]);
  const [error, setError] = useState();
  const [keyword, setKeyword] = useState();

  const { userData } = useContext(UserContext);
  
  const tableRef = useRef(null);

  useEffect(() => {
    fetchRegisteredReports();
  }, []);

  const fetchRegisteredReports = async () => {
    fetch(`${process.env.REACT_APP_BACK_DOMAIN}/search-all/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem("auth-token"),
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setReports(data);
      });
  };

  const editReports = async (id = false, status) => {
    if (status) {
      if (window.confirm("¿Desea cambiar el estado de este Reportaje?")) {
        await fetch(
          `${process.env.REACT_APP_BACK_DOMAIN}/delete/${id}/${userData?.user?.displayName}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-auth-token": localStorage.getItem("auth-token"),
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            alert("Reportaje modificado");
            fetchRegisteredReports();
          })
          .catch((err) => console.error("Entró al error ", err));
      }
    }
  };

  const getCurrentDate = (date) => {
    return new Date(Date.parse(date) - 1000 * 60 * 60 * 5).toISOString();
  };

  const getStateIcon = (state) => {
    let renderIcon = null;
    if (state) {
      renderIcon = <i className="material-icons notranslate">delete</i>;
    } else {
      renderIcon = <i className="material-icons notranslate">close</i>;
    }
    return renderIcon;
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Reporte Reportajes',
    sheet: 'Pagina 1'
  })

  return (
    <div className="page">
      <div className="container">
        <h2>Lista de Reportajes</h2>
        {error && (
          <ErrorNotice message={error} clearError={() => setError(undefined)} />
        )}

        {
          <div>
            Escriba el usuario a buscar:
            <div className="input-field inline">
              <input
                id="key_word"
                name="key_word"
                onChange={(e) => setKeyword(e.target.value)}
                type="text"
              />
            </div>
            <button id="exportButton" className="material-icons center notranslate" onClick={onDownload}>cloud_download</button>
          </div>
        }
        <br></br>
        <br></br>
      </div>
      <table className="striped" id="reportsInform" ref={tableRef}>
        <thead>
          <tr>
            <th>Número</th>
            <th>ID</th>
            <th>Url</th>
            <th>Interno</th>
            <th>Estado</th>
            <th>Fecha de creación</th>
            <th>Creador</th>
            <th>Fecha de Actualización</th>
            <th>Modificador</th>
            {userData?.user && userData?.user?.role === "administrador" ? (
              <th>Eliminar Reportaje</th>
            ) : (
              <th></th>
            )}
          </tr>
        </thead>
        <tbody>
          {reports.map((report, index) => {
            if (report.idFile.includes(keyword) || keyword === undefined) {
              return (
                <tr key={report._id}>
                  <td>{index + 1}</td>
                  <td>
                    <p style={{ width: "150px", overflowWrap: "break-word" }}>
                      {report.idFile}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "300px", overflowWrap: "break-word" }}>
                      <a
                        href={report.reportUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {report.reportUrl}
                      </a>
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "50px" }}>
                      {report.isInternal === "true" ? "Si" : "No"}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "80px" }}>
                      {report.status ? "Activo" : "Inactivo"}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>
                      {report.created ? getCurrentDate(report.created) : ""}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "100px" }}>{report.adminCreator}</p>
                  </td>
                  <td>
                    <p style={{ width: "200px" }}>
                      {report.updated ? getCurrentDate(report.updated) : ""}
                    </p>
                  </td>
                  <td>
                    <p style={{ width: "100px" }}>{report.adminUpdate}</p>
                  </td>
                  {userData?.user?.role !== "administrador" ? (
                    <td></td>
                  ) : (
                    <td>
                      <button
                        className="btn"
                        style={{ margin: "4px" }}
                        onClick={() => editReports(report?._id, report?.status)}
                      >
                        {getStateIcon(report?.status)}
                      </button>
                    </td>
                  )}
                </tr>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
