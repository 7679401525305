import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";

export default function UserFilter() {
  const [userid, setUserid] = useState();
  const [comments, setComments] = useState([]);

  const { userData } = useContext(UserContext);
  const history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("auth-token") === '') history('/admin/login');
  })

  const getComments = async (e) => {
    if (userid) {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/user-all/${userid}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("auth-token")
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setComments(data);
        });
    }
    if (e) {
      e.preventDefault();
    }
  };

  const editComment = (id = false) => {
    if (window.confirm("¿Desea cambiar el estado de este comentario?")) {
      fetch(`${process.env.REACT_APP_BACK_DOMAIN}/delete/${id}/${userData.user.displayName}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("auth-token")
        },
      })
        .then((res) => res.json())
        .then((data) => {
          alert("Comentario modificado");
          getComments();
        })
        .catch((err) => console.error("Entró al error ", err));
    }
  };

  const getCurrentDate = (date) => {
    return new Date(Date.parse(date) - 1000 * 60 * 60 * 5).toISOString();
  }


  const getStateIcon = (state) => {

    let renderIcon = null;
    if (state) {
      renderIcon = (<i className="material-icons notranslate">delete</i>);
    } else {
      renderIcon = (<i className="material-icons notranslate">undo</i>);
    }
    return renderIcon;
  }

  return (
    <div className="page">
      <div className="container">
        <h2>Usuarios</h2>
        <form onSubmit={getComments}>
          <div className="row">
            <div className="col s12">
              Inserte el dato del usuario:
              <div className="input-field inline">
                <input
                  id="user_id"
                  name="userid"
                  onChange={(e) => setUserid(e.target.value)}
                  type="text"
                  className="validate"
                  value={userid}
                />
              </div>
              <button type="submit" style={{ margin: "4px" }} name="action">
                <i className="material-icons center notranslate">search</i>
              </button>
            </div>
          </div>
        </form>
      </div>

      <table className="striped" id="userResults">
        <thead>
          <tr>
            <th>Número</th>
            <th>Comentario</th>
            <th>Estado</th>
            <th>Fecha de creación</th>
            <th>UID</th>
            <th>Canonica</th>
            <th>Fecha de Actualización</th>
            <th>Modificador</th>
            {
              userData.user && userData.user.role === 'administrador' ?
                <th>Cambiar Estado</th> :
                <th></th>
            }
          </tr>
        </thead>
        <tbody>
          {comments.map((comment, index) => {
            return (
              <tr key={comment._id}>
                <td>{index + 1}</td>
                <td>
                  <p style={{ width: "250px", overflowWrap: "break-word" }}>
                    {comment.content}
                  </p>
                  <p style={{ width: "250px", overflowWrap: "break-word" }}>
                    <strong>Autor: </strong>
                    {`${comment.firstName} - ${comment.lastName} - ${(comment.userName)}`}
                  </p>
                </td>
                <td>
                  <p style={{ width: "70px" }}>
                    {comment.status ? "No borrado" : "borrado"}
                  </p>
                </td>
                <td>
                  <p style={{ width: "200px" }}>
                    {getCurrentDate(comment.created)}
                  </p>
                </td>
                <td>
                  <p style={{ width: "150px" }}>{comment.userId}</p>
                </td>
                <td>
                  <p style={{ width: "200px", overflowWrap: "break-word" }}>
                    {comment.articleUrl}
                  </p>
                </td>
                <td>
                  <p style={{ width: "200px" }}>
                    {comment.updated
                      ? getCurrentDate(comment.updated)
                      : ""}
                  </p>
                </td>
                <td>
                  <p style={{ width: "100px" }}>{comment.adminUpdate}</p>
                </td>
                {
                  userData.user && userData.user.role === 'administrador' ?
                    <td>
                      <button
                        className="btn blue-grey lighten-2"
                        style={{ margin: "4px" }}
                        onClick={() => editComment(comment._id)}
                      >
                        {getStateIcon(comment.status)}
                      </button>
                    </td> :
                    <td></td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

