// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button {
  font-size: 0.875rem;
  line-height: 1rem;
  font-family: lft-etica, sans-serif;
  padding: 0.625rem 1rem;
  border-radius: 0.625rem;
  border: none;
  width: auto;
  align-self: center;
  cursor: pointer;
  transition: transform .3s ease;
}
.Button-Container{
  margin-top: 12px;
}
.Button_primary {
  background-color: #e00000;
  color: #fff;
}
.Button_primary:hover {
  background-color: #a90000;
  box-shadow: 0 8px 20px #f48484;
  transform: translateY(-3px);
}
.Button_secondary {
  background-color: #434343;
  color: #fff;
}
.Button_secondary:hover {
  background-color: #707070;
}
.Button_tertiary {
  border: 1px solid #e00000;
  color: #e00000;
  background-color: transparent;
  padding: 0.563rem 1rem;
}
.Button_tertiary:hover {
  background-color: #a90000;
  border-color: #a90000;
  color: #fff;
}
.Button:disabled {
  background-color: #eee;
  color: #5a5a5a;
}
.Button_remove {
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.125rem;
  font-size: 1rem;
  line-height: 1rem;
  box-sizing: border-box;
  border: none;
  background-color: transparent;
  color: #e00000;
}`, "",{"version":3,"sources":["webpack://./src/styles/button.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,kCAAkC;EAClC,sBAAsB;EACtB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,8BAA8B;AAChC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,8BAA8B;EAC9B,2BAA2B;AAC7B;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,6BAA6B;EAC7B,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,qBAAqB;EACrB,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,cAAc;AAChB;AACA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,sBAAsB;EACtB,YAAY;EACZ,6BAA6B;EAC7B,cAAc;AAChB","sourcesContent":[".Button {\n  font-size: 0.875rem;\n  line-height: 1rem;\n  font-family: lft-etica, sans-serif;\n  padding: 0.625rem 1rem;\n  border-radius: 0.625rem;\n  border: none;\n  width: auto;\n  align-self: center;\n  cursor: pointer;\n  transition: transform .3s ease;\n}\n.Button-Container{\n  margin-top: 12px;\n}\n.Button_primary {\n  background-color: #e00000;\n  color: #fff;\n}\n.Button_primary:hover {\n  background-color: #a90000;\n  box-shadow: 0 8px 20px #f48484;\n  transform: translateY(-3px);\n}\n.Button_secondary {\n  background-color: #434343;\n  color: #fff;\n}\n.Button_secondary:hover {\n  background-color: #707070;\n}\n.Button_tertiary {\n  border: 1px solid #e00000;\n  color: #e00000;\n  background-color: transparent;\n  padding: 0.563rem 1rem;\n}\n.Button_tertiary:hover {\n  background-color: #a90000;\n  border-color: #a90000;\n  color: #fff;\n}\n.Button:disabled {\n  background-color: #eee;\n  color: #5a5a5a;\n}\n.Button_remove {\n  width: 1.25rem;\n  height: 1.25rem;\n  padding: 0.125rem;\n  font-size: 1rem;\n  line-height: 1rem;\n  box-sizing: border-box;\n  border: none;\n  background-color: transparent;\n  color: #e00000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
