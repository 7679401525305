import React, { useState, useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import Axios from "axios";
import ErrorNotice from "../misc/ErrorNotice";
import { validateToken } from "../../utilities/functions";

export default function Register() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordCheck, setPasswordCheck] = useState();
  const [displayName, setDisplayName] = useState();
  const [role, setRole] = useState({});
  const [users, setUsers] = useState([]);
  const [error, setError] = useState();
  const [keyword, setKeyword] = useState();

  const { userData } = useContext(UserContext);
  const history = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem("auth-token");
    if (authToken) {
      validateToken(authToken).then(result => {
        if (result?.error) {
          localStorage.setItem("auth-token", "");
          localStorage.setItem("user", "");
          history('/admin/login');
        }
      });
    }
    if (!authToken) {
      history('/admin/login');
    }

    fetchRegisteredUsers();
  }, [history]);

  const fetchRegisteredUsers = async () => {
    const authToken = localStorage.getItem("auth-token");
    const res = await fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users/list`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        'x-auth-token': `${authToken}`,
        "Content-Type": "application/json"
      },
    });
    const data = await res.json();
    setUsers(data);
    setEmail("");
    setPassword("");
    setPasswordCheck("");
    setDisplayName("");
    setRole("");
  };

  const submit = async (e) => {
    e.preventDefault();
    try {
      const authToken = localStorage.getItem("auth-token");
      if (userData?.user) {
        const creator = userData?.user?.displayName;
        const newUser = { email, password, passwordCheck, displayName, role, creator };
        await Axios.post(
          `${process.env.REACT_APP_BACK_DOMAIN}/users/register`,
          newUser,
          {
            headers: {
              "x-auth-token": authToken,
              "Content-Type": "application/json",
            },
          }
        );
        alert("Usuario insertado correctamente");
        fetchRegisteredUsers();
      }
    } catch (err) {
      if (err.response?.data?.msg) {
        setError(err.response.data.msg);
      }
    }
  };

  const getCurrentDate = (date) => {
    return new Date(Date.parse(date) - 1000 * 60 * 60 * 5).toISOString();
  };

  const getStateIcon = (state) => {
    let renderIcon = null;
    if (state) {
      renderIcon = <i className="material-icons notranslate">delete</i>;
    } else {
      renderIcon = <i className="material-icons notranslate">undo</i>;
    }
    return renderIcon;
  };

  const editUser = (id = false) => {
    try {
      const authToken = localStorage.getItem("auth-token");
      if (window.confirm("¿Desea cambiar el estado de este usuario?")) {
        fetch(`${process.env.REACT_APP_BACK_DOMAIN}/users/edit/${id}/${userData?.user?.displayName}`, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            'x-auth-token': `${authToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then(() => {
            alert("Usuario actualizado correctamente");
            fetchRegisteredUsers();
          })
          .catch((err) => console.error("Entró al error ", err));
      }
    } catch (err) {
      if (err.response?.data?.msg) {
        setError(err.response.data.msg);
      }
    }
  };

  return (
    <div className="page">
      <div className="container">
        <h2>Registrar</h2>
        {error && (
          <ErrorNotice message={error} clearError={() => setError(undefined)} />
        )}

        {userData?.user?.role === 'administrador' ? (
          <>
            <form className="Form" onSubmit={submit} autoComplete="off">
              <div className="Form-Field">
                <label className="Form-Label" htmlFor="register-email">Correo</label>
                <input
                  autoComplete="off"
                  className="Form-Input"
                  id="register-email"
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />

              </div>

              <div className="Form-Field">
                <label className="Form-Label" htmlFor="register-password">Contraseña</label>
                <input
                  autoComplete="off"
                  className="Form-Input"
                  id="register-password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />

              </div>
              <div className="Form-Field">
                <label className="Form-Label" htmlFor="register-vpassword">Verificar contraseña</label>
                <input
                  autoComplete="off"
                  className="Form-Input"
                  id="register-vpassword"
                  type="password"
                  onChange={(e) => setPasswordCheck(e.target.value)}
                  value={passwordCheck}
                />

              </div>

              <div className="Form-Field">
                <label className="Form-Label" htmlFor="register-display-name">Nombre de Usuario</label>
                <input
                  autoComplete="off"
                  className="Form-Input"
                  id="register-display-name"
                  type="text"
                  onChange={(e) => setDisplayName(e.target.value)}
                  value={displayName}
                />

              </div>

              <div className="Form-Field">
                <label className="Form-Label" htmlFor="role">Rol: </label>
                <select className="Form-Input" name="role" id="role" value={role} onChange={(e) => setRole(e.target.value)}>
                  <option>--- Seleccione una opción ---</option>
                  <option value="administrador">Administrador</option>
                  <option value="redactor">Edición</option>
                </select>
              </div>

              <input className="Button Button_primary" type="submit" value="Registrar" />
            </form>

            <div>
              Escriba el usuario a buscar:
              <div className="input-field inline">
                <input
                  autoComplete="off"
                  className="Form-Input"
                  id="key_word"
                  name="key_word"
                  onChange={(e) => setKeyword(e.target.value)}
                  type="text"
                />
              </div>
            </div>
          </>
        ) : ''}

        <br />
        <br />
      </div>
      <table className="striped striped_users" id="usersReport">
        <thead>
          <tr>
            <th>Número</th>
            <th>Email</th>
            <th>Username</th>
            <th>Rol</th>
            <th>Estado</th>
            <th>Fecha de creación</th>
            <th>Creador</th>
            <th>Fecha de Actualización</th>
            <th>Modificador</th>
            <th>Modificar usuario</th>
          </tr>
        </thead>
        <tbody>
          {users?.map((user, index) => {
            if (user?.displayName?.includes(keyword) || keyword === undefined) {
              return (
                userData?.user?.role === 'administrador' || user?.displayName === userData?.user?.displayName ? (
                  <tr key={user._id}>
                    <td>{index + 1}</td>
                    <td>
                      <p style={{ width: "200px" }}>{user?.email}</p>
                    </td>
                    <td>
                      <p style={{ width: "150px" }}>{user?.displayName}</p>
                    </td>
                    <td>
                      <p style={{ width: "90px" }}>{user?.role}</p>
                    </td>
                    <td>
                      <p style={{ width: "65px" }}>
                        {user?.status ? "Activo" : "Inactivo"}
                      </p>
                    </td>
                    <td>
                      <p style={{ width: "170px" }}>
                        {user?.created ? getCurrentDate(user?.created) : ""}
                      </p>
                    </td>
                    <td>
                      <p style={{ width: "150px" }}>{user?.creator}</p>
                    </td>
                    <td>
                      <p style={{ width: "170px" }}>
                        {user?.updated ? getCurrentDate(user?.updated) : ""}
                      </p>
                    </td>
                    <td>
                      <p style={{ width: "150px" }}>{user?.modificator}</p>
                    </td>
                    <td>
                      {userData?.user?.role !== 'administrador' ? '' : (
                        <button
                          className="btn"
                          style={{ margin: "4px" }}
                          onClick={() => editUser(user?._id)}
                        >
                          {getStateIcon(user?.status)}
                        </button>
                      )}
                      <Link to={`/admin/update?id=${user?._id}`}>
                        <i className="material-icons notranslate">perm_identity</i>
                      </Link>
                    </td>
                  </tr>
                ) : ''
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
